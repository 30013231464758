import { searchParamsHelper } from '@utils/searchParamsHelper';
import { makeAutoObservable } from 'mobx';

import { ALL_FILTERS } from '@/mocks/filters';
import { mockedData } from '@/mocks/items';
import { Filter, GlobalItem, ItemAge } from '@/shared/types/common';

class ShopStore {
  cards = mockedData;
  filters: Map<string, Filter> = new Map();
  price: { min: number; max: number } = { min: 0, max: 1000 };
  ageEnabled = false;
  chromaEnabled = false;
  constructor() {
    makeAutoObservable(this);
  }
  setPrice(price: { min: number; max: number }) {
    this.price = price;
  }
  toggleFilter(filter: Filter) {
    const filterKey = filter.filter;
    const currentFilter = this.filters.get(filterKey);
    if (currentFilter) {
      this.filters.delete(filterKey);
    } else {
      const foundFilter = ALL_FILTERS.find(f => f.filter === filterKey);
      if (foundFilter) {
        this.filters.set(foundFilter.filter, foundFilter);
      }
    }
  }

  resetFilters() {
    this.filters = new Map();
    this.setPrice({ min: 0, max: 1000 });
  }
  toggleAgeEnabled() {
    this.ageEnabled = !this.ageEnabled;
  }

  toggleChromaEnabled() {
    this.chromaEnabled = !this.chromaEnabled;
  }

  setPetProperties(item: GlobalItem, tabId?: string | number) {
    const newItemProps = item?.properties?.find(props => props?.id === tabId);

    if (!newItemProps) return;

    const updatedItem: GlobalItem = {
      ...item,
      propertiesSetId: newItemProps?.id,
      tagCategory: newItemProps?.tagCategory,
      info: {
        ...item?.info,
        price: newItemProps?.price,
      },
    };

    const updatedCards = this.cards?.map(cardItem =>
      cardItem?.propertiesSetId === item?.propertiesSetId
        ? updatedItem
        : cardItem,
    );

    this.cards = [...updatedCards];
  }
  setInitialFilters(params: Partial<Record<keyof typeof this, any>>) {
    for (let key of Object.values(params).flat()) {
      const currentFilter = ALL_FILTERS.find(item => item.filter === key);
      if (currentFilter?.group === 'age') {
        this.ageEnabled = true;
      }
      currentFilter &&
        !this.filters.has(key) &&
        this.filters.set(key, currentFilter);
    }
  }

  setPetAge(item: GlobalItem, tabId: ItemAge) {
    const updatedItem: GlobalItem = {
      ...item,
      info: {
        ...item?.info,
        age: tabId,
      },
    };

    const updatedCards = this.cards?.map(cardItem =>
      cardItem?.propertiesSetId === item?.propertiesSetId
        ? updatedItem
        : cardItem,
    );

    this.cards = [...updatedCards];
  }
  get getMappedCards() {
    const mappedCards = new Map<string | number, GlobalItem>();

    this.cards?.map(item => mappedCards?.set(item?.id, item));

    return mappedCards;
  }

  get getFilters() {
    return this.filters;
  }

  get getFiltersAsSearchParams() {
    const result: Record<string, string[]> = {};
    const arrayOfFilters = Array.from(this.filters);

    for (const [key, value] of arrayOfFilters) {
      if (!result[value.group]) {
        result[value.group] = [value.filter];
      } else {
        result[value.group].push(value.filter);
      }
    }
    return {
      params: result,
      paramsAsString: searchParamsHelper(result),
    };
  }
}

export const shopStore = new ShopStore();
