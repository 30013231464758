import localforage from 'localforage';
import { makeAutoObservable } from 'mobx';
import { isHydrated, makePersistable } from 'mobx-persist-store';

class LiveFeedStore {
  liveFeedSort: 'all' | 'exclusive' = 'all';
  constructor() {
    makeAutoObservable(this);
    makePersistable(
      this,
      {
        name: 'livefeed',
        properties: ['liveFeedSort'],
        storage: typeof window !== 'undefined' ? localforage : undefined,
        expireIn: 86400000, // One day in milliseconds
        removeOnExpiration: true,
        stringify: false,
        debugMode: true,
      },
      { delay: 200, fireImmediately: false },
    ).then(r => {
      //console.log('persist',r);
    });
  }

  setLiveFeedStore(sort: 'all' | 'exclusive') {
    this.liveFeedSort = sort;
  }

  get isHydrated() {
    return isHydrated(this);
  }
}

export const liveFeedStore = new LiveFeedStore();
