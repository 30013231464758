const localePrefix: 'as-needed' | 'always' | 'never' = 'always';

// FIXME: Update this configuration file based on your project information

/*langs*/
export const GERMAN = 'de';
export const PORTUGUESE = 'pt';
export const BRAZIL = 'pt';
export const ITALIAN = 'it';
export const SPANISH = 'es';
export const TURKISH = 'tr';
export const PHILIPPINE = 'ph';
export const FRENCH = 'fr';
export const ENGLISH = 'en';
/*langs*/

export const EUR = 'eur';
export const USD = 'usd';

export const ADOPT = 'adopt';
export const MM2 = 'mm2';

export const games = [ADOPT, MM2] as const;

export const locales = [
  ENGLISH,
  /* GERMAN,
  BRAZIL,
  ITALIAN,
  SPANISH,
  TURKISH,
  PORTUGUESE,
  PHILIPPINE,
  FRENCH,*/
] as const;

export const currencies = [USD, EUR] as const;

export const AppConfig = {
  name: 'Nextjs Starter',
  locales: locales,
  defaultLocale: locales[0],
  localePrefix,
};

export type Locale = (typeof AppConfig)['locales'][number];
export type Currency = (typeof currencies)[number];
export type Game = (typeof games)[number];
