'use client';

import React, { ReactNode, Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { NavHistoryProvider } from '@/shared/providers/nav-history-provider';
import { store } from '@/store';
import { StoreProvider } from '@/store/context';
interface Props {
  children: ReactNode;
}

const queryClient = new QueryClient();

const Providers = ({ children }: Props) => {
  return (
    <StoreProvider store={store}>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <NavHistoryProvider>{children}</NavHistoryProvider>
        </QueryClientProvider>
      </Suspense>
    </StoreProvider>
  );
};

export default Providers;
